import React, { useState } from 'react';

export const Monday = () => {
  // Authentication States
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [boardId, setBoardId] = useState();
  const [token, setToken] = useState();
  const [loggedIn, setLoggedIn] = useState(false);
  const [loginError, setLoginError] = useState(false);

  // Customer Information States
  const [name, setName] = useState("");
  const [phone, setPhone] = useState("");
  const [email, setEmail] = useState("");
  const [date, setDate] = useState("");
  const [notes, setNotes] = useState("");

  // Hardcoded Users (for demonstration purposes)
  const users = [
    {
      username: 'testuser',
      password: 'testpass',
    },
    {
      username: 'shanell',
      password: 'examprep2024!',
      boardId: "2941867723",
      token: "token"
    },
    {
      username: 'test3',
      password: 'testpass1',
    },
  ];

  // Handle User Login
  const handleLogin = (e) => {
    e.preventDefault();
    let user = users.find(
      (user) => user.username === username && user.password === password
    );

    if (user) {
      setLoggedIn(true);
      setLoginError(false);
      setBoardId(user.boardId);
      setToken(user.token);
    } else {
      setLoginError(true);
    }
  };

  // Handle User Logout
  const handleLogout = () => {
    setUsername('');
    setPassword('');
    setBoardId('');
    setToken('');
    setLoggedIn(false);
  };

  // Submit Customer Information to CRM
  const submitToCRM = () => {
    let query5 = `mutation ($myItemName: String!, $columnVals: JSON!) { create_item (board_id:${boardId}, item_name:$myItemName, column_values:$columnVals) { id } }`;
    let vars = {
      myItemName: `${name}`,
      columnVals: JSON.stringify({
        name: `${name}`,
        status: { label: "Hot" },
        text7: `${phone}`,
        text9: `${email}`,
        date4: { date: `${date}`, time: "00:00:00" },
        long_text: `${notes}`
      })
    };

    fetch("https://api.monday.com/v2", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: token ?? ''
      },
      body: JSON.stringify({
        query: query5,
        variables: vars
      })
    })
      .then(res => res.json())
      .then(res => {
        console.log(JSON.stringify(res, null, 2));
        alert(`Data saved in CRM.`);
        setName("");
        setPhone("");
        setEmail("");
        setDate("");
        setNotes("");
      });
  };

  return (
    <div>
      {/* Authentication Section */}
      <div id="CRMLoginSection">
        {!loggedIn ? (
          <div className="max-w-md mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
            <h2 className="text-xl font-semibold pt-2 pb-4 text-center">CRM Login</h2>
            <form onSubmit={handleLogin} className="space-y-4">
              <input
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="text"
                placeholder="Username"
                value={username}
                onChange={(e) => setUsername(e.target.value)}
              />
              <input
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="password"
                placeholder="Password"
                value={password}
                onChange={(e) => setPassword(e.target.value)}
              />
              <button
                type="submit"
                className="w-full bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded-md"
              >
                Login
              </button>
              {loginError && (
                <p className="text-red-500 text-center">Incorrect username or password.</p>
              )}
            </form>
          </div>
        ) : (
          <div className="text-right block text-gray-600">
            Logged in as <b>{username}</b> &nbsp;
            <button
              className="bg-blue-500 hover:bg-blue-700 text-white font-bold py-2 px-4 rounded"
              onClick={handleLogout}
            >
              Logout
            </button>
          </div>
        )}
      </div>

      {/* Customer Information Form */}
      {loggedIn && (
        <div className="max-w-4xl mx-auto mt-8 p-6 bg-white shadow-md rounded-lg">
          <h2 className="text-xl font-semibold pb-4 text-center">Customer Information</h2>
          <div className="flex items-center space-x-4 mb-6">
            <input
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              placeholder="Name"
              value={name}
              onChange={(e) => setName(e.target.value)}
            />
            <input
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="text"
              placeholder="Phone"
              value={phone}
              onChange={(e) => setPhone(e.target.value)}
            />
            <input
              className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
              type="email"
              placeholder="Email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
            <div className="w-full">
              <input
                className="w-full px-4 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring-2 focus:ring-blue-500"
                type="date"
                value={date}
                onChange={(e) => setDate(e.target.value)}
              />
              <label className="block text-gray-700 text-sm mt-1">Follow Up Date</label>
            </div>
          </div>
          {/* Additional Notes and Submit Button */}
          <div className="flex items-center mb-6">
            <textarea
              className="flex-grow p-2 border border-gray-300 rounded-md"
              placeholder="Additional Notes"
              rows="4"
              value={notes}
              onChange={(e) => setNotes(e.target.value)}
            />
            <button
              className="ml-4 bg-blue-500 text-white font-bold py-2 px-4 rounded-md"
              onClick={submitToCRM}
            >
              Submit to CRM
            </button>
          </div>
        </div>
      )}
    </div>
  );
};