import { Classes } from './components/Classes'; // Adjust the path as needed
// import Monday from './components/Monday'; // Adjust the path as needed
import Header from './components/Header'; // Adjust the path as needed
import './index.css';  // This is crucial for loading the Tailwind styles

function App() {
  return (
    <>
      <div className="bg-gray-100">
        <Header />
        {/* <Monday /> */}
        <Classes />
      </div>
    </>
  );
}

export default App;
