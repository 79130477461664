// Invoice.jsx
import React from "react";
import jsPDF from "jspdf";

// Import the base64 image
import { logoBase64 } from "./logoBase64"; // Adjust the path if necessary

export const Invoice = ({ selectedClass, selectedVariation, booksArr }) => {
  const createInvoice = () => {
    // Initialize jsPDF
    const doc = new jsPDF();

    // Company Info
    const companyInfo = {
      name: "Nashville Training Center",
      addressLine1: "5000 Linbar Dr #250",
      cityStateZip: "Nashville TN 37211",
      phone: "(615) 832-4550",
    };

    // Invoice Data
    const invoiceData = {
      companyName: companyInfo.name,
      companyAddress: companyInfo.addressLine1,
      companyCityStateZip: companyInfo.cityStateZip,
      companyPhone: companyInfo.phone,
      invoiceDate: new Date().toLocaleDateString(),
      courseDescription: selectedClass.name,
      courseDate: selectedVariation.date,
      courseTime: selectedVariation.event_text,
      courseLocation: `${selectedVariation.name}, ${selectedVariation.address}, ${selectedVariation.city}, ${selectedVariation.state}, ${selectedVariation.zip}`,
      coursePrice: selectedClass.price || 0,
      books: booksArr.map((book) => ({
        name: book.name,
        price: book.price || 0,
      })),
      salesTax: 0, // Adjust as needed
    };

    // Calculate totals
    const booksTotal = invoiceData.books.reduce(
      (acc, book) => acc + book.price,
      0
    );
    const subtotal = invoiceData.coursePrice + booksTotal;
    const total = subtotal + invoiceData.salesTax;

    // Set font size smaller
    doc.setFontSize(10);

    // Add company logo using the base64 image
    doc.addImage(logoBase64, "PNG", 10, 10, 50, 15);

    // Company Info
    doc.text(invoiceData.companyName, 70, 15);
    doc.text(invoiceData.companyAddress, 70, 20);
    doc.text(invoiceData.companyCityStateZip, 70, 25);
    doc.text(`Phone: ${invoiceData.companyPhone}`, 70, 30);

    // Invoice Title
    doc.setFontSize(14);
    doc.text("Invoice", 150, 15);

    // Invoice Details
    doc.setFontSize(10);
    doc.text(`Date: ${invoiceData.invoiceDate}`, 150, 25);

    // Line separator
    doc.line(10, 40, 200, 40);

    // Table Headers
    const startY = 45;
    doc.text("Description", 10, startY);
    doc.text("Qty", 130, startY);
    doc.text("Price", 150, startY);
    doc.text("Amount", 170, startY);

    // Draw a line under headers
    doc.line(10, startY + 2, 200, startY + 2);

    // Table Content
    let currentY = startY + 10;
    const lineHeight = 6;

    // Helper function to wrap text
    const splitTextAndGetHeight = (text, x, y, maxWidth) => {
      const textLines = doc.splitTextToSize(text, maxWidth);
      doc.text(textLines, x, y);
      return textLines.length * lineHeight;
    };

    // Course Line Item
    const courseDesc = `${invoiceData.courseDescription}\nDate: ${invoiceData.courseDate}\nTime: ${invoiceData.courseTime}\nLocation: ${invoiceData.courseLocation}`;
    const courseDescHeight = splitTextAndGetHeight(
      courseDesc,
      10,
      currentY,
      110
    );
    doc.text("1", 130, currentY);
    doc.text(`$${invoiceData.coursePrice.toFixed(2)}`, 150, currentY);
    doc.text(`$${invoiceData.coursePrice.toFixed(2)}`, 170, currentY);

    currentY += courseDescHeight + 2;

    // Books Line Items
    invoiceData.books.forEach((book) => {
      const descHeight = splitTextAndGetHeight(book.name, 10, currentY, 110);
      doc.text("1", 130, currentY);
      doc.text(`$${book.price.toFixed(2)}`, 150, currentY);
      doc.text(`$${book.price.toFixed(2)}`, 170, currentY);

      currentY += descHeight + 2;
    });

    // Line separator above totals
    doc.line(10, currentY, 200, currentY);
    currentY += 5;

    // Totals
    doc.text(
      `Subtotal: $${subtotal.toFixed(2)}`,
      170,
      currentY,
      null,
      null,
      "right"
    );
    currentY += lineHeight;
    doc.text(
      `Sales Tax: $${invoiceData.salesTax.toFixed(2)}`,
      170,
      currentY,
      null,
      null,
      "right"
    );
    currentY += lineHeight;
    doc.setFontSize(12);
    doc.text(`Total: $${total.toFixed(2)}`, 170, currentY, null, null, "right");
    doc.setFontSize(10);
    currentY += lineHeight + 5;

    // Footer Message - Centered
    doc.setFontSize(10);
    const footerMessages = [
      "We accept Visa, Mastercard, American Express, Discover, Money Orders, and Cash.",
      "Sorry, we cannot accept checks.",
      "All sales are final!",
    ];
    footerMessages.forEach((msg) => {
      doc.text(msg, 105, currentY, null, null, "center");
      currentY += lineHeight;
    });

    currentY += lineHeight;

    // Thank You Message - Centered with updated phone number
    doc.setFontSize(8);
    const thankYouMessage =
      "Thank you for your business. If we can assist you in any other way, please call us at (615) 832-4550 or visit us at www.examprep.org.";
    const thankYouLines = doc.splitTextToSize(thankYouMessage, 180);
    doc.text(thankYouLines, 105, currentY, null, null, "center");

    // Save the PDF with the specified name
    const invoiceDateForFilename = new Date()
      .toLocaleDateString()
      .replace(/\//g, "-"); // Replace slashes with dashes for filename
    const fileName = `Examprep Invoice ${invoiceDateForFilename}.pdf`;
    doc.save(fileName);
  };

  return (
    <button
      className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md"
      onClick={createInvoice}
    >
      Create Invoice
    </button>
  );
};