import React from 'react';

const Header = () => {
  return (
    <header className="relative flex items-center bg-white p-4 border-b-2 border-blue-800">
      <div className="absolute left-0 flex items-center">
        <a href="/">
          <img src="/logo.png" alt="NOVA Logo" className="h-10 mr-4 pl-4" />
        </a>
      </div>
      <nav className="mx-auto flex items-center">
        <a href="/classes" className="text-gray-600 hover:text-black mx-4 text-lg">Classes</a>
        <a href="/shipping" className="text-gray-600 hover:text-black mx-4 text-lg">Shipping</a>
        <a href="/instructors" className="text-gray-600 hover:text-black mx-4 text-lg">Instructors</a>
      </nav>
    </header>
  );
};

export default Header;