// Classes.jsx

import { useEffect, useState } from "react";
import React from "react";
import Select from "react-select";
import parse from "html-react-parser";
import swal from "sweetalert";

// Import your data and components
import courses from "../json-data/courses.json";
import stateNames from "../json-data/states.json";
import Bundles from "../json-data/bundles.json";
import Books from "../json-data/books.json";
import Courses from "../json-data/courses.json";
import { Monday } from "./Monday.jsx";
import { Invoice } from "./Invoice.jsx";

export const Classes = () => {
  const [coursesToDisplay, setCoursesToDisplay] = useState(courses);
  const [selectedState, setSelectedState] = useState(null);
  const [selectedClass, setSelectedClass] = useState(null);
  const [booksArr, setBooksArr] = useState([]);
  const [courseVariations, setCourseVariations] = useState([]);
  const [selectedSchedule, setSelectedSchedule] = useState(null);

  useEffect(() => {
    if (selectedState) {
      let t = courses?.filter(
        (k) =>
          k.state && k.state.toLowerCase() === selectedState.name.toLowerCase()
      );
      setCoursesToDisplay(t);
    }
  }, [selectedState]);

  useEffect(() => {
    if (selectedClass) {
      let bundle = Bundles?.filter((k) => k.course_id === selectedClass.id);

      const arr = [];

      bundle.forEach((elem) => {
        elem.books_ids.forEach((bookId) => {
          let w = Books?.find((k) => k.id === bookId);
          if (w !== undefined) arr.push(w);
        });
      });
      setBooksArr(arr);
    }
  }, [selectedClass]);

  useEffect(() => {
    if (selectedClass) {
      let course = Courses?.find((k) => k.id === selectedClass.id);
      let arr = [];
      course?.variations?.forEach((elem, idx) => {
        arr.push(elem); // Store the entire variation object
      });
      setCourseVariations(arr);
    }
  }, [selectedClass]);

  // Helper function to generate slug
  const generateSlug = (text) => {
    return text
      .toLowerCase()
      .replace(/[^a-z0-9]+/g, '-') // Replace non-alphanumeric characters with hyphens
      .replace(/^-+|-+$/g, ''); // Trim hyphens from start and end
  };

  // Copy to Email Functionality
  const copyToEmail = () => {
    let k = [];
    booksArr?.forEach((book) => {
      k.push(book?.name?.trim());
    });
    let booksNames = k.join(",\r\n");

    // Update schedule text
    let schedule = courseVariations
      ?.map((variation) => {
        return `${variation.date} - ${variation.event_text} - ${variation.name} - ${variation.address}, ${variation.city}, ${variation.state}, ${variation.zip} - ${variation.phone}`;
      })
      .join(",\r\n");

    let dummy = document.createElement("textarea");
    let text = `Dear Customer,\r\n\r\nHere is the information you requested:\r\nSchedule:\r\n${schedule}\r\nBooks Requested:\r\n${booksNames}`;
    document.body.appendChild(dummy);
    dummy.value = text;
    dummy.select();
    document.execCommand("copy");
    document.body.removeChild(dummy);
    swal("Copied to clipboard!");
  };

  // Add to Cart Functionality
  const addToCart = () => {
    if (!selectedClass || !selectedState) {
      swal("Please select a state and a course before proceeding.");
      return;
    }

    const stateSlug = generateSlug(selectedState.name);
    const courseSlug = generateSlug(selectedClass.name);

    const productPageUrl = `https://www.examprep.org/contractors-license/seminars/${stateSlug}/${courseSlug}/`;

    // Open the product page URL in a new browser tab
    window.open(productPageUrl, '_blank');
  };

  return (
    <>
      <div className="min-h-screen bg-grey-100 p-4">
        {/* Wrap the Monday component in a container with the same width */}
        <div className="max-w-4xl mx-auto">
          {/* Include the Monday component and pass necessary props */}
          <Monday booksArr={booksArr} courseVariations={courseVariations} />
        </div>

        {/* Adjusted Course Section to match the same width */}
        <div className="max-w-4xl mx-auto mt-8 p-4 bg-white shadow-md rounded-lg">
          <h2 className="text-xl font-semibold pb-4 text-center">Course</h2>
          <table className="w-full">
            <tbody>
              <tr>
                <td className="w-1/4">
                  <Select
                    placeholder="State"
                    options={stateNames}
                    getOptionLabel={(stateNames) => stateNames.name}
                    getOptionValue={(stateNames) => stateNames.id}
                    onChange={setSelectedState}
                  />
                </td>
                <td className="w-3/4">
                  <Select
                    placeholder="Choose or Search Course"
                    options={coursesToDisplay}
                    getOptionLabel={(courses) => courses.name}
                    getOptionValue={(courses) => courses.id}
                    onChange={setSelectedClass}
                  />
                </td>
              </tr>
            </tbody>
          </table>
        </div>

        {/* Selected Course Info */}
        {selectedClass && (
          <div className="max-w-4xl mx-auto mt-8">
            {/* Course Information Container */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <h3 className="text-3xl font-bold text-gray-800 text-center">
                {selectedClass.name}
              </h3>
              <div className="text-lg font-semibold text-green-600 text-center mt-2">
                ${selectedClass.price?.toFixed(2) || " Price not available"}
              </div>
              {/* Action Buttons */}
              <div className="flex justify-center mt-4 space-x-4">
                <button
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md"
                  onClick={copyToEmail}
                >
                  Copy to Email
                </button>
                <button
                  className="bg-blue-500 text-white font-bold py-2 px-4 rounded-md"
                  onClick={addToCart}
                >
                  Add to Cart
                </button>
                {/* Use the Invoice component */}
                {selectedSchedule !== null ? (
                  <Invoice
                    selectedClass={selectedClass}
                    selectedVariation={courseVariations[selectedSchedule]}
                    booksArr={booksArr}
                  />
                ) : (
                  <button
                    className="bg-gray-500 text-white font-bold py-2 px-4 rounded-md cursor-not-allowed"
                    disabled
                  >
                    Create Invoice
                  </button>
                )}
              </div>
            </div>

            {/* Course Schedule */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <h5 className="text-3xl font-semibold mb-4 text-center">Course Schedule</h5>
              {courseVariations?.length > 0 ? (
                <table className="min-w-full table-auto">
                  <thead>
                    <tr>
                      <th className="px-2 py-2"></th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Date
                      </th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Time
                      </th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Location
                      </th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Address
                      </th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Phone Number
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {courseVariations.map((variation, idx) => (
                      <tr key={idx} className="border-t">
                        <td className="px-2 py-2 text-gray-700">
                          <input
                            type="radio"
                            name="scheduleOption"
                            value={idx}
                            checked={selectedSchedule === idx}
                            onChange={() => setSelectedSchedule(idx)}
                          />
                        </td>
                        <td className="px-2 py-2 text-gray-700">
                          {variation.date}
                        </td>
                        <td className="px-2 py-2 text-gray-700">
                          {variation.event_text}
                        </td>
                        <td className="px-2 py-2 text-gray-700">
                          {variation.name}
                        </td>
                        <td className="px-2 py-2 text-gray-700">
                          {variation.address}, {variation.city},{" "}
                          {variation.state}, {variation.zip}
                        </td>
                        <td className="px-2 py-2 text-gray-700">
                          {variation.phone}
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-700">
                  No schedule available for this course.
                </p>
              )}
            </div>

            {/* Required Books */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <h5 className="text-3xl font-semibold mb-4 text-center">Required Books</h5>
              {booksArr?.length > 0 ? (
                <table className="min-w-full table-auto">
                  <thead>
                    <tr>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Title
                      </th>
                      <th className="px-2 py-2 text-left text-gray-700 font-semibold">
                        Price
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    {booksArr.map((book, idx) => (
                      <tr key={idx} className="border-t">
                        <td className="px-2 py-2 text-gray-700">{book.name}</td>
                        <td className="px-2 py-2 text-gray-700">
                          ${book.price ? book.price.toFixed(2) : "N/A"}
                        </td>
                      </tr>
                    ))}
                    <tr>
                      <td className="px-2 py-2 text-right text-gray-700 font-semibold">
                        Total:
                      </td>
                      <td className="px-2 py-2 text-gray-700 font-semibold">
                        $
                        {booksArr
                          .reduce((acc, book) => acc + (book.price || 0), 0)
                          .toFixed(2)}
                      </td>
                    </tr>
                  </tbody>
                </table>
              ) : (
                <p className="text-gray-700">No books required.</p>
              )}
            </div>

            {/* Course Details */}
            <div className="bg-white shadow-md rounded-lg p-6 mb-6">
              <h2 className="text-3xl font-semibold mb-4 text-center">Details</h2>
              <div className="mt-4">
                {/* Requirements */}
                <h3 className="text-xl font-bold mb-2">Requirements</h3>
                <div className="prose prose-sm text-gray-700">
                  {parse(
                    JSON.parse(JSON.stringify(selectedClass.requirements))
                  )}
                </div>

                {/* Services */}
                <h3 className="text-xl font-bold mt-4 mb-2">Services</h3>
                <div className="prose prose-sm text-gray-700">
                  {parse(JSON.parse(JSON.stringify(selectedClass.services)))}
                </div>

                {/* Additional Sections */}
                {selectedClass.applications && (
                  <>
                    <h3 className="text-xl font-bold mt-4 mb-2">
                      Applications
                    </h3>
                    <div className="prose prose-sm text-gray-700">
                      {parse(
                        JSON.parse(JSON.stringify(selectedClass.applications))
                      )}
                    </div>
                  </>
                )}
                {selectedClass.reciprocity && (
                  <>
                    <h3 className="text-xl font-bold mt-4 mb-2">
                      Reciprocity
                    </h3>
                    <div className="prose prose-sm text-gray-700">
                      {parse(
                        JSON.parse(JSON.stringify(selectedClass.reciprocity))
                      )}
                    </div>
                  </>
                )}
                {selectedClass.continuing_education && (
                  <>
                    <h3 className="text-xl font-bold mt-4 mb-2">
                      Continuing Education
                    </h3>
                    <div className="prose prose-sm text-gray-700">
                      {parse(
                        JSON.parse(
                          JSON.stringify(selectedClass.continuing_education)
                        )
                      )}
                    </div>
                  </>
                )}
                {selectedClass.exam && (
                  <>
                    <h3 className="text-xl font-bold mt-4 mb-2">Exam</h3>
                    <div className="prose prose-sm text-gray-700">
                      {parse(JSON.parse(JSON.stringify(selectedClass.exam)))}
                    </div>
                  </>
                )}
              </div>
            </div>
          </div>
        )}
      </div>
    </>
  );
};